import * as React from "react"
import Layout from "../components/layout";
import Hero from "../components/hero";

import {Container, Typography} from "@mui/material";

// markup
const LicenseAgreementPage = () => {
    return (
        <Layout title={"License agreement"}>
            <main>
                <Hero title={"License Agreement"}
                      subtitle={""} />

                <Container style={{paddingTop: '4em'}}>
                    <Typography variant={"h5"} marginTop={3}>JURYBOX TECHNOLOGIES, LLC SOFTWARE LICENSE AGREEMENT</Typography>
                    <Typography variant={"body2"}>
                        This Software License Agreement ("Agreement") is entered between you, the purchaser and user of the Jurybox software and services ("Licensee"), and Jurybox Technologies, LLC ("Licensor"), individually referred to in this Agreement as a "Party" and collectively as the "Parties".
                    </Typography>
                    <Typography variant={"body2"}>In consideration of the benefits and obligations exchanged in this Agreement, the Parties agree as follows:</Typography>


                    <Typography variant={"h5"} marginTop={3}>ARTICLE 1. THE SOFTWARE</Typography>

                    <Typography variant={"body2"}>1.01. "Software" Defined. The term "Software" as used in this agreement shall mean all computer programs licensed under this Agreement, including all related files, documentation, and other materials, whether in whole or in part, including any and all modifications, derivative works, and copies of the foregoing, regardless of the form or media in or on which they may exist. The Software shall consist of any and all of Licensor’s online legal hosted software services, which include its core selection software solution, Jurybox, and any future and/or upgraded versions.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 2. LICENSE GRANT AND LIMITATIONS</Typography>
                    <Typography variant={"body2"}>2.01. Grant of License. Licensor hereby grants, and Licensee hereby accepts, a nontransferable, non-exclusive, worldwide, and royalty-free license to use the Software, subject to the conditions and for the period specified in this Agreement.</Typography>
                    <Typography variant={"body2"}>2.02. Use of Software. Licensee agrees to use the Software for its sole and exclusive benefit and its own and internal purposes.</Typography>
                    <Typography variant={"body2"}>2.03. Copies. (a) Except as provided in this Agreement, Licensee shall not copy any portion of the Software.</Typography>
                    <Typography variant={"body2"}>(b) Licensee shall reproduce and include Licensor’s copyright notice, patent notice, trademark, service mark, other proprietary markings, and/or confidential legends on any copies of the Software, including any modifications, adaptations, or conversions.</Typography>
                    <Typography variant={"body2"}>2.04. Licensee’s Responsibilities. Licensee shall be exclusively responsible for the supervision, management, and control of its use of the Software, including, but not limited to:</Typography>
                    <Typography variant={"body2"}>(a) Assuring proper configuration of equipment or devices;</Typography>
                    <Typography variant={"body2"}>(b) Establishing adequate operating methods; and</Typography>
                    <Typography variant={"body2"}>(c) Implementing procedures sufficient to satisfy its obligations for security under this Agreement, including appropriate action between it and its employees to prevent misuse, unauthorized copying, modification, or disclosure of the Software.</Typography>
                    <Typography variant={"body2"}>2.05. Term. The term of this license will be agreed to in the online subscription Licensee completes, and shall automatically renew for successive one (1) year terms, as set forth in the online subscription. The online subscription is incorporated herein by this reference and is made part of this Agreement.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 3. PROPERTY RIGHTS</Typography>
                    <Typography variant={"body2"}>3.01. Ownership Rights to Software. (a) Licensee acknowledges and agrees that the Software is the confidential and proprietary property of Licensor, and except to the extent expressly authorized in this Agreement, Licensee receives no rights to and will not sell, assign, lease, market, transfer, encumber or otherwise suffer to exist any lien or security interest on, nor allow any third person, firm, company, or other entity to copy, reproduce or disclose the Software, whether in whole, in part, or in any manner whatsoever.</Typography>
                    <Typography variant={"body2"}>(b) Licensor shall retain complete ownership of all modifications and derivative works provided to Licensee as part of any maintenance, support, upgrades, updates, or services. In no event shall any support, maintenance, services or Software provided to Licensee by Licensor pursuant to this Agreement be deemed to be based on any "work made for hire" agreement between the Parties.</Typography>
                    <Typography variant={"body2"}>3.02. Nondisclosure of Software. Licensee acknowledges that the Software is the confidential and proprietary property of Licensor. Licensee agrees to hold it in trust and not to sell, rent, license, distribute, transfer, or, directly or indirectly, disclose or permit the sale, rental, licensing, distribution, transfer, or disclosure of the Software or its contents to any other party, and to use its best efforts to prevent inadvertent disclosure of the Software to any third party, either during the term of this Agreement or thereafter.</Typography>
                    <Typography variant={"body2"}>3.03. Degree of Care. Licensee further agrees to instruct its personnel to keep the Software confidential by using the same care and discretion that they use with other data designated by Licensee as confidential.</Typography>
                    <Typography variant={"body2"}>3.04. Disclosure Constitutes Breach. Licensee agrees that any disclosure of the Software to a third party constitutes a material breach of this Agreement and shall terminate the license granted by this Agreement.</Typography>
                    <Typography variant={"body2"}>3.05. Damages for Breach. Licensee further agrees that it shall be strictly liable for all damages to Licensor that result from any disclosure of the Software to any third party.</Typography>
                    <Typography variant={"body2"}>3.06. Copyright or Other Marks. Licensee agrees not to remove, deface, or destroy any copyright, patent notice, trademark, service mark, other proprietary markings, or confidential legends placed on or within the Software.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 4. PAYMENT</Typography>
                    <Typography variant={"body2"}>4.01. Periodic Payment. In payment for the license granted under this Agreement, Licensee shall pay Licensor in accordance with the online subscription.</Typography>
                    <Typography variant={"body2"}>4.02. License Fee Increases. Licensor may increase the periodic license fee for any of its software provided for hereunder on 30-days notice to Licensee. The increased license fee shall become effective on the date specified in the notice of the increase unless Licensee terminates this Agreement by 10-days notice to Licensor.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 5.  SUPPORT</Typography>
                    <Typography variant={"body2"}>5.01. Technical Support. Licensor make reasonable efforts to provide technical support to Licensee and its authorized users upon request from Licensee or its authorized users. Such requests may be made by contacting support@juryboxapp.com.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 6. WARRANTY, INDEMNIFICATION AND LIMITATION OF LIABILITY</Typography>
                    <Typography variant={"body2"}>6.01. Warranty.</Typography>
                    <Typography variant={"body2"}>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, LICENSOR MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, IN LAW OR FROM A COURSE OF DEALING OR USE OF TRADE, AS TO ANY MATTER, INCLUDING THOSE MERCHANTABILITY, SATISFACTORY QUALITY, TITLE, FITNESS FOR PARTICULAR PURPOSE, OR NON-INFRINGEMENT. LICENSOR DOES NOT WARRANT THAT THE SOFTWARE OR THE SERVICES WILL MEET ALL OF LICENSEE’S REQUIREMENTS OR THAT THE USE OF THE SOFTWARE OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. THE SOFTWARE AND SERVICES ARE PROVIDED TO LICENSEE ON AN "AS IS" BASIS AND YOUR USE OF SOFTWARE AND SERVICES IS AT YOUR OWN RISK, INCLUDING, WITHOUT LIMITATION, COMPLIANCE WITH ANY LAWS, RULES OR REGULATIONS. THE PARTIES EXPRESSLY ACKNOWLEDGE THAT THE DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PARTY OF THE AGREEMENT. LICENSOR HEREBY EXPRESSLY DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT LICENSEE’S USE OF THE SOFTWARE AND SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAW, RULES OR REGULATIONS. THIS DISCLAIMER APPLIES TO BUT IS NOT LIMITED TO ANY FEDERAL OR STATE STATUTES OR REGULATIONS THAT MAY BE APPLICABLE TO LICENSEE. LICENSEE IS SOLELY RESPONSIBLE FOR ENSURING THAT LICENSEE’S USE OF THE SOFTWARE AND SERVICES IS IN ACCORDANCE WITH APPLICABLE LAW. IF LICENSEE IS DISSATISFIED WITH THE SERVICES OR THIS AGREEMENT, LICENSEE’S SOLE AND EXCLUSIVE REMEDY IS TO STOP USING THE SERVICES.</Typography>
                    <Typography variant={"body2"}>6.02. Indemnification by Licensor. (a) Except as otherwise provided, Licensor, at its own expense, will defend, indemnify, and hold Licensee harmless from any claim made or threatened or any suit or proceeding brought against Licensee insofar as it is based on an allegation that any Software furnished to Licensee under this Agreement infringes any copyright or patent in existence on the date the Software was initially provided to Licensee, but only if Licensee does all of the following:</Typography>
                    <Typography variant={"body2"}>Notifies Licensor of that action in writing within a reasonable period of time (such that Licensor suffers no prejudice to its rights);</Typography>
                    <Typography variant={"body2"}>Gives Licensor the right to control and direct the defense and settlement of that action;</Typography>
                    <Typography variant={"body2"}>Makes no compromise, settlement, or admission of liability; and</Typography>
                    <Typography variant={"body2"}>Provides reasonable assistance and cooperates in the defense of that action.</Typography>
                    <Typography variant={"body2"}>Licensor will have no responsibility for the settlement of any claim, suit, or proceeding made by Licensee without Licensor’s prior written approval.</Typography>
                    <Typography variant={"body2"}>(b) If any Software is held to infringe and the use of that Software is enjoined, Licensor, at its expense, will do one of the following:</Typography>
                    <Typography variant={"body2"}>Procure for Licensee the right to continue using the infringing or potentially infringing Software;</Typography>
                    <Typography variant={"body2"}>Replace the infringing or potentially infringing Software with non-infringing Software; or</Typography>
                    <Typography variant={"body2"}>Modify the infringing or potentially infringing Software so that it become non-infringing.</Typography>
                    <Typography variant={"body2"}>If none of the foregoing remedies are commercially feasible, then Licensor will return to Licensee any license fees paid for that Software, and upon such a return, any licenses granted to Licensee for that Software shall terminate immediately.</Typography>
                    <Typography variant={"body2"}>(c) Licensor’s obligations as stated in this Section will not apply to any claim, suit, or proceeding to the extent it is based on any of the following:</Typography>
                    <Typography variant={"body2"}>Any modification of the Software other than by Licensor or the combination of the Software with non-Licensor hardware or software, if the claim, suit or proceeding would have been avoided if the Software had not been so modified or combined;</Typography>
                    <Typography variant={"body2"}>Licensee’s use of other than the latest release of the Software if Licensee is informed that a claim, suit or proceeding can be avoided by use of the latest release;</Typography>
                    <Typography variant={"body2"}>Any use of the Software not authorized by this Agreement; or</Typography>
                    <Typography variant={"body2"}>A modification or derivative work made by Licensor based on Licensee’s instructions, designs or specifications.</Typography>
                    <Typography variant={"body2"}>(d) This Section 6.03 sets forth the entire obligation of Licensor, and Licensee’s exclusive remedy, for the actual or alleged infringement by any Software of any patent, copyright, trade secret or other intellectual property right of any person or entity.</Typography>
                    <Typography variant={"body2"}>6.04. Indemnification by Licensee. Licensee shall defend, indemnify, and hold Licensor harmless, under the same terms and conditions and to the same extent as Licensor’s indemnification obligation pursuant to Section 6.03, from and against any and all claims asserted by a third party against Licensor to the extent such a claim is based or alleges the infringement of that third party’s intellectual property rights by (1) modification(s) of the Software made by Licensee, or (2) derivative works made by Licensee.</Typography>
                    <Typography variant={"body2"}>6.05. Limitation of Liability.</Typography>
                    <Typography variant={"body2"}>IN NO EVENT WILL EITHER PARTY BE LIABLE TO EACH OTHER OR TO ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL OR SPECIAL DAMAGES, EVEN IF THE PARTY TO BE CHARGED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL LICENSOR’S TOTAL LIABILITY UNDER ANY OR ALL PROVISIONS OF THIS AGREEMENT FOR ALL CAUSES OF ACTION ON A CUMULATIVE BASIS EXCEED THE PAYMENTS ACTUALLY MADE TO LICENSOR UNDER THIS AGREEMENT FOR ALL SOFTWARE OR SERVICES OR SUPPORT OR MAINTENANCE RESULTING IN LIABILITY OR OBLIGATIONS ON LICENSOR’S PART DURING THE IMMEDIATELY PRECEDING 1 YEAR PERIOD.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 7. TERMINATION</Typography>
                    <Typography variant={"body2"}>7.01. Grounds for Automatic Termination. The license granted by this Agreement shall be terminated automatically and without further notice upon the occurrence of any of the following:</Typography>
                    <Typography variant={"body2"}>(a) Disclosure of the Software to a third party, whether directly by Licensee or its authorized users or indirectly and whether inadvertently or otherwise.</Typography>
                    <Typography variant={"body2"}>(b) Refusal by Licensee to pay any periodic license fee or any increase in that fee provided for in this Agreement.</Typography>
                    <Typography variant={"body2"}>(c) Cessation of business by Licensee or any successor or assign to whom the Software has been legitimately transferred.</Typography>
                    <Typography variant={"body2"}>(d) Commission by Licensee of an event of default as defined herein.</Typography>
                    <Typography variant={"body2"}>7.02. Events of Default. Licensee shall have committed an event of default, and this Agreement and the license granted hereunder shall terminate, if any of the following occur:</Typography>
                    <Typography variant={"body2"}>(a) Licensee attempts to use, copy, license, or convey the Software in any manner contrary to the terms of this Agreement or in derogation of Licensor’s proprietary rights in the Software.</Typography>
                    <Typography variant={"body2"}>(b) Licensee fails or neglects to perform or observe any of its existing or future obligations under this Agreement, including, without limitation, the timely payment of any sums due Licensor.</Typography>
                    <Typography variant={"body2"}>(c) Licensee makes an assignment of Licensee’s business for the benefit of creditors.</Typography>
                    <Typography variant={"body2"}>(d) A petition in bankruptcy is filed by or against Licensee.</Typography>
                    <Typography variant={"body2"}>(e) A receiver, trustee in bankruptcy, or similar officer is appointed to take charge of all or part of Licensee’s property.</Typography>
                    <Typography variant={"body2"}>(f) Licensee is adjudicated a bankrupt.</Typography>
                    <Typography variant={"body2"}>7.03. Effect of Termination. Licensee agrees that immediately upon the operation of Section 7.01, whether or not it receives notice of termination, all fees or charges due for the remaining term of this Agreement shall immediately become due and payable. Upon termination of the license granted hereunder, Licensor’s obligations under this Agreement shall cease. Licensor shall not be responsible or liable for any lost data and/or information upon termination of this Agreement.</Typography>

                    <Typography variant={"h5"} marginTop={3}>ARTICLE 8. GENERAL TERMS AND CONDITIONS</Typography>
                    <Typography variant={"body2"}>8.01. Notices. Unless otherwise provided in this Agreement, any notice required or permitted by this Agreement to be given to either party shall be deemed to have been duly given if in writing and delivered personally, by e-mail, or mailed by first-class, registered, or certified mail, postage prepaid and addressed to Licensee. Licensee may provide notice to Licensor by sending it in writing by email to support@juryboxapp.com.</Typography>
                    <Typography variant={"body2"}>8.02. Assignment of Contract. Licensee shall not assign or otherwise transfer its rights under this Agreement, including the license granted hereunder, without the prior written consent of Licensor. Any attempt to make such an assignment without Licensor’s consent shall be void.</Typography>
                    <Typography variant={"body2"}>8.03. Amendments/Modifications. Licensor may change the Agreement from time to time to accurately reflect its Software and any updates or upgrades Licensor makes to the Software.</Typography>
                    <Typography variant={"body2"}>8.04. Non-waiver. Licensor and Licensee agree that no failure to exercise and no delay in exercising any right, power, or privilege hereunder on the part of either party shall operate as a waiver of any right, power, or privilege. Licensor and Licensee further agree that no single or partial exercise of any right, power, or privilege hereunder shall preclude its further exercise.</Typography>
                    <Typography variant={"body2"}>8.05. Attorneys’ Fees. If any legal action is necessary to enforce the terms of this Agreement, the prevailing party shall be entitled to reasonable attorneys’ fees in addition to any other relief to which that party may be entitled. This provision shall be construed as applicable to the entire Agreement.</Typography>
                    <Typography variant={"body2"}>8.06. Severability. If any part of this Agreement is adjudged by any court of competent jurisdiction to be invalid, that judgment shall not affect or nullify the remainder of this Agreement, and the effect shall be confined to the part immediately involved in the controversy adjudged.</Typography>
                    <Typography variant={"body2"}>8.07. Governing Law. This Agreement shall be deemed to have been made in, and shall be construed pursuant to, the laws of the State of California.</Typography>
                    <Typography variant={"body2"}>8.08. Entire Agreement. Licensee acknowledges and agrees that this Agreement is the complete and exclusive statement of the mutual understanding of the parties, and that it supersedes and cancels all previous written and oral agreements and communications relating to the subject matter of this Agreement.</Typography>
                    <Typography variant={"body2"}>8.09. Effect of Purchase Order. In the event of any conflict between this Agreement and the terms and conditions of any purchase order or similar document pursuant to which Licensee acquired the license granted by this Agreement, the terms and conditions of this Agreement shall control.</Typography>

                    <br/>
                    <br/>

                    <Typography variant={"body2"}>UPDATED: July 1, 2020</Typography>

                </Container>
            </main>
        </Layout>
    )
}

export default LicenseAgreementPage
